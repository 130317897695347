button {
    line-height: 2.25rem !important;
    border-radius: 0.25rem !important;

    &.btn {
      text-transform: none !important;

      &.secondary {
        color: white;
        background-color: $secondary-800;
      }
      &.error {
        color: white;
        background-color: $brand-orange-intense;
      }
    }

    &.icon-btn {
      width: 2.5rem;
      height: 2.5rem;
      flex-grow: 0;
      padding: 0.5rem;
      border-radius: 3.125rem !important;
      border: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      mat-icon {
        color: $on-primary;
      }
      &.primary {
        background-color: $primary-40;
      }
      &.secondary {
        background-color: $secondary-700;
      }
      &.disabled {
        mat-icon {
          color: $on-surface-38;
        }
        background-color: rgba(33, 33, 33, 0.08);
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14)
      }
    }
  }

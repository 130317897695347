@use 'sass:map';
/* For use in src/lib/core/theming/_palette.scss */
$dark-green: (
  50: #eaf1eb,
  100: #ccddce,
  200: #aac7ae,
  300: #87b08d,
  400: #6e9f74,
  500: #548e5c,
  600: #4d8654,
  700: #437b4a,
  800: #3a7141,
  900: #295f30,
  A100: #a7ffb1,
  A200: #74ff84,
  A400: #41ff57,
  A700: #27ff40,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$grey: (
  50: #f0f0f1,
  100: #dadbdd,
  200: #c1c3c7,
  300: #a8aab0,
  400: #95989f,
  500: #82868e,
  600: #7a7e86,
  700: #6f737b,
  800: #656971,
  900: #52565f,
  A100: #cbdafc,
  A200: #9bb8f9,
  A400: #6292ff,
  A700: #4880ff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

@import "./colors/theme-colors";
@import "./elements/button.scss";

$stepper-width: 19rem;
$mobile-stepper-height: 5.688rem;

$values: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  '0_75': 0.75rem,
  3: 1rem,
  '1_25': 1.25rem,
  4: 1.5rem,
  '1_75': 1.75rem,
  5: 2rem,
  6: 2.5rem,
  7: 3rem,
  8: 3.5rem,
  9: 4rem,
  10: 4.5rem,
  11: 5rem,
  12: 5.5rem,
  13: 6rem,
  14: 6.5rem,
  15: 7rem,
  16: 7.5rem,
  17: 8rem
);

$utilities: (
  "ls-margin": (
    property: margin,
    responsive: true,
    class: ls-m,
    values: $values
  ),
  "ls-margin-bottom": (
    property: margin-bottom,
    responsive: true,
    class: ls-mb,
    values: $values
  ),
  "ls-margin-right": (
    property: margin-right,
    responsive: true,
    class: ls-mr,
    values: $values
  ),
  "ls-margin-top": (
    property: margin-top,
    responsive: true,
    class: ls-mt,
    values: $values
  ),
  "ls-margin-left": (
    property: margin-left,
    responsive: true,
    class: ls-ml,
    values: $values
  ),
  "ls-padding": (
    property: padding,
    responsive: true,
    class: ls-p,
    values: $values
  ),
  "ls-padding-top": (
    property: padding-top,
    responsive: true,
    class: ls-pt,
    values: $values
  ),
  "ls-padding-bottom": (
    property: padding-bottom,
    responsive: true,
    class: ls-pb,
    values: $values
  ),
  "ls-padding-left": (
    property: padding-left,
    responsive: true,
    class: ls-pl,
    values: $values
  ),
  "ls-padding-right": (
    property: padding-right,
    responsive: true,
    class: ls-pr,
    values: $values
  )
);
$enable-grid-classes: false;
$enable-cssgrid: true;

@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  margin: 0;
}
/*      CUSTOM COLORS       */
$c-dark-green: #548e5c;
$c-green: #82bc7d;
$c-light-green: #badb95;
$c-light-gray: #82868e;
$c-gray: #4c4c4c;
$c-purple: #983271;
$c-orange: #f59a1e;
$c-error: #ff5722;

/*      MEDIA WIDTHS        */
$layout-breakpoint-xs: 599.98px;
$layout-breakpoint-s: 959.98px;
$layout-breakpoint-m: 1279.98px;
$layout-breakpoint-l: 1919.98px;
$layout-breakpoint-xl: 1920px;

.wide-input {
  width: 15.625rem;
}

.extra-wide-input {
  width: 20rem;
}

.nav-back {
  width: 6.25rem;
}

.base-route-container {
  margin: 1rem 1rem 6rem;
}

.kv-columns {
  word-break: break-word;
}

::ng-deep .ls-signalr-reconnected {
  background: $c-dark-green;
  color: white;
  .mat-simple-snackbar-action {
    color: white;
  }
}

::ng-deep .ls-signalr-reconnecting {
  background: $tertiary-0;
  color: white;
  .mat-simple-snackbar-action {
    color: white;
  }
}

::ng-deep .ls-signalr-lost {
  background: darkred;
  color: white;
  .mat-simple-snackbar-action {
    color: white;
  }
}

::ng-deep .successful-sb {
  background: $c-dark-green;
  color: white;
  .mat-simple-snackbar-action {
    color: white;
  }
}

::ng-deep .unsuccessful-sb {
  background: darkred;
  color: white;
  .mat-simple-snackbar-action {
    color: white;
  }
  span {
    align-self: center;
  }
}

.wide-snack-bar > span {
  padding-right: 4rem;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0.25rem;
}

.ls-alert {
  color: $c-orange;
}

:host ::ng-deep .mat-form-field {
  line-height: 2.125 !important;
}

/* data grid */
/* sticky columns */
:host ::ng-deep .ls-data-grid .table-container table.mat-table.cdk-table {
  border-collapse: separate;

  tr.selected-row {
    .ls-grid-link {
      color: $primary-20;
    }
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding: 0 1rem;
  }

  th.mat-header-cell {
    color: rgba(0, 0, 0, 0.87);
    padding: 0 1rem;
  }

  .element-row {
    td {
      padding: 0 1rem;
    }
  }

  .mat-table-sticky.mat-table-sticky-border-elem-left {
    border-right: 1px solid #777;
    box-shadow: 3px 0 6px -1px rgba(0,0,0,0.5);
  }

  .mat-table-sticky.mat-table-sticky-border-elem-right {
    border-left: 1px solid #777;
    box-shadow: -3px 0px 7px -2px rgba(0,0,0,0.5);
  }
}

:host ::ng-deep .mat-paginator {
  color: $on-surface-high-emphasis;
}

:host ::ng-deep .mat-paginator-icon {
  fill: $on-surface-high-emphasis;
}

// Sets the time picker button to fill input, when input is clicked opens timepicker
// A new method for HTMLInputElement is being added to show pickers more easily, but it is only supported on the latest Chrome version right now
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/showPicker

.time-input{
  display: inline-block;
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  z-index: 2;
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: calc(100% + 12.5px);
  position: absolute;
  left: -20px;
  right: 0;
  top: -20px;
  width: 100%;
}

input[type="time"]::-webkit-datetime-edit-hour-field, input[type="time"]::-webkit-datetime-edit-minute-field{
  z-index: 3;
  position: relative;
}

.side-sheet {
  max-width: 75% !important;
  height: 100% !important;
  margin-bottom: 2rem;
  .modal-content {
    min-height: 100% !important;
  }
}

.ls-side-sheet-modal {
  @extend #{ '.modal-frame','.modal-right','.modal-dialog-scrollable','.side-sheet' };
}

.ls-side-sheet-container {
  @extend #{ '.frame','.right' };
}
